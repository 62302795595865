/** @jsx jsx */
import { jsx } from 'theme-ui';

import MainLayout from './MainLayout';
import { useLocalStorateTzOffset } from '../components/TimezonePicker';

interface ProgramDetailLayoutProps {
  children: ({ tzOffset }: { tzOffset: number }) => React.ReactNode;
}

export const ProgramDetailLayout: React.FC<ProgramDetailLayoutProps> = ({
  children,
}) => {
  const { tzOffset } = useLocalStorateTzOffset();

  return (
    <MainLayout>
      <div id="content" />
      {typeof children === 'function'
        ? children({ tzOffset: tzOffset as number })
        : children}
    </MainLayout>
  );
};
